export const tags = [
  'database',
  'networking',
  'compiler',
  'cloud computing',
  'web technologies',
  'data structures and algorithms',
  'mathematics',
  'machine learning',
];
